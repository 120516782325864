import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    ButtonToolbar,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col, Table
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Loader from "../../components/Loader/Loader";
import GoBack from "../../components/GoBack";
import { useTranslation } from "react-i18next";
import axios from "axios";
import qs from "qs";

const EmailTemplates = ({ location }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = useState({
        query: {},
        searchTimeout: null,
        modalOpen: false,
        idToDelete: null,
        searchStr:
            qs.parse(location.search, { ignoreQueryPrefix: true }).name || "",
        emailTemplates: [],
        isReceiving: true,
        hasMore: false,
        currentPage: 0,
        hasNext: false, // To determine if there are more pages
    });

    const itemsPerPage = 50;

    // const searchEmailTemplates = (e) => {
    //     let { value } = e.target;
    //     setState((prev) => ({
    //         ...prev,
    //         searchStr: value,
    //     }));

    //     if (state.searchTimeout) {
    //         clearTimeout(state.searchTimeout);
    //         setState((prev) => ({
    //             ...prev,
    //             searchTimeout: null,
    //         }));
    //     }

    //     setState((prev) => ({
    //         ...prev,
    //         searchTimeout: setTimeout(() => {
    //             history.push({
    //                 pathname: `/admin/email-templates`,
    //                 search: qs.stringify({
    //                     ...state.query,
    //                     page: 0,
    //                     q: value,
    //                 }),
    //             });
    //         }, 300),
    //     }));
    // };

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });

        setState((prev) => ({
            ...prev,
            query: {
                ...query,
                page: parseInt(query.page) || 0,
            },
        }));
    }, [location]);

    useEffect(() => {
        // Fetch the email templates from the API using limit and offset
        const fetchEmailTemplates = async () => {
            setState((prev) => ({
                ...prev,
                isReceiving: true,
            }));
            try {
                const limit = itemsPerPage + 1;
                const offset = state.currentPage * (limit - 1); // Offset = current page * limit

                const response = await axios.get('/email-templates/', {
                    params: {
                        ...state.query,
                        limit,
                        offset, // Adding limit and offset to the query
                    },
                });

                const templates = response.data.slice(0, itemsPerPage);
                const hasNext = response.data.length > itemsPerPage; // If we get 50, there might be more

                setState((prev) => ({
                    ...prev,
                    emailTemplates: templates,
                    isReceiving: false,
                    hasNext,
                }));
            } catch (error) {
                console.error("Error fetching email templates:", error);
                setState((prev) => ({
                    ...prev,
                    isReceiving: false,
                }));
            }
        };

        fetchEmailTemplates();
    }, [state.query, state.currentPage]);

    const titleFormatter = (cell, row) => {
        return cell ? (
            <Link
                to={`/admin/email-templates/${row.id}/detail`}
            >
                {cell[0].toUpperCase() + cell.slice(1)}
            </Link>
        ) : (
            ""
        );
    };

    const handleDelete = async () => {
        setState((prev) => ({
            ...prev,
            isDeleting: true,
        }));
        try {
            await axios.delete(`/email-templates/${state.idToDelete}`);
            setState((prev) => ({
                ...prev,
                modalOpen: false,
                idToDelete: null,
                isDeleting: false,
            }));
            // Refetch email templates after deletion
            const limit = itemsPerPage;
            const offset = state.currentPage * limit;
            const response = await axios.get('/email-templates/', {
                params: {
                    ...state.query,
                    limit,
                    offset,
                },
            });
            const templates = response.data.slice(0, itemsPerPage);
            const hasNext = response.data.length === itemsPerPage;

            setState((prev) => ({
                ...prev,
                emailTemplates: templates,
                hasNext,
            }));
        } catch (error) {
            console.error("Error deleting email template:", error);
            setState((prev) => ({
                ...prev,
                isDeleting: false,
            }));
        }
    };

    const openModal = (cell) => {
        setState((prev) => ({
            ...prev,
            modalOpen: true,
            idToDelete: cell,
        }));
    };

    const closeModal = () => {
        setState((prev) => ({
            ...prev,
            modalOpen: false,
        }));
    };

    const apiFormatter = (cell, row) => {
        return (
            <ButtonToolbar className="justify-content-end">
                <Button
                    color="success"
                    size="xs"
                    onClick={() =>
                        history.push(`/admin/email-templates/${row.id}/detail`)
                    }
                >
                    <span className="d-none d-md-inline-block">
                        {t("Edit")}
                    </span>
                    <span className="d-md-none">
                        <i className="la la-edit" />
                    </span>
                </Button>

                <Button
                    onClick={() => {
                        openModal(row.id);
                    }}
                    color="danger"
                    size="xs"
                >
                    {state.isDeleting && state.idToDelete === row.id ? (
                        <Loader size={14} />
                    ) : (
                        <span>
                            <span className="d-none d-md-inline-block">
                                {t("Delete")}
                            </span>
                            <span className="d-md-none">
                                <i className="la la-remove" />
                            </span>
                        </span>
                    )}
                </Button>
            </ButtonToolbar>
        );
    };

    const create = () => {
        history.push(
            `/admin/email-templates/create` +
            (state.query.type ? "?type=" + state.query.type : "")
        );
    };

    // Pagination handlers
    const goToNextPage = () => {
        if (state.hasNext) {
            setState((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const goToPreviousPage = () => {
        if (state.currentPage > 0) {
            setState((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    return (
        <div>
            <GoBack />
            <Row className="filter align-items-center mb-4">
                <Col md={3} xs={12}>
                    <h2>{t("Email templates")}</h2>
                </Col>
                <Col md={3} xs={12}></Col>
                <Col xl={3} xs={12}></Col>
                <Col className="d-flex align-items-end justify-content-end" md={3} xs={12}>
                    <Button color="success" onClick={() => create()}>
                        {t("Create new")}
                    </Button>
                </Col>
            </Row>

            {state.isReceiving ? (
                <Loader size={45} />
            ) : (
                <>
                    <div className="table-responsive">
                        <Table className="table-bordered table-lg">
                            <thead>
                                <tr>
                                    <th>{t("Name")}</th>
                                    <th>{t("Email Key")}</th>
                                    <th>{t("Subject")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.emailTemplates.map((template) => (
                                    <tr key={template.id}>
                                        <td>
                                            <Link
                                                to={`/admin/email-templates/${template.id}/detail`}
                                            >
                                                {template.name}
                                            </Link>
                                        </td>
                                        <td>{template.emailKey}</td>
                                        <td>{template.subject}</td>
                                        <td>
                                            <ButtonToolbar className="justify-content-end">
                                                <Button
                                                    color="success"
                                                    size="xs"
                                                    onClick={() =>
                                                        history.push(
                                                            `/admin/email-templates/${template.id}/detail`
                                                        )
                                                    }
                                                >
                                                    <span className="d-none d-md-inline-block">
                                                        {t("Edit")}
                                                    </span>
                                                    <span className="d-md-none">
                                                        <i className="la la-edit" />
                                                    </span>
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        openModal(template.id);
                                                    }}
                                                    color="danger"
                                                    size="xs"
                                                >
                                                    {state.isDeleting &&
                                                        state.idToDelete ===
                                                        template.id ? (
                                                        <Loader size={14} />
                                                    ) : (
                                                        <span>
                                                            <span className="d-none d-md-inline-block">
                                                                {t("Delete")}
                                                            </span>
                                                            <span className="d-md-none">
                                                                <i className="la la-remove" />
                                                            </span>
                                                        </span>
                                                    )}
                                                </Button>
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </Table>
                    </div>


                    {/* Pagination */}

                </>
            )}

            <Modal size="sm" isOpen={state.modalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                    {t("Confirm delete")}
                </ModalHeader>
                <ModalBody className="bg-white">
                    {t("Are you sure you want to delete this item?")}
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={closeModal}>
                        {t("Cancel")}
                    </Button>
                    <Button color="danger" onClick={handleDelete}>
                        {state.isDeleting ? <Loader /> : t("Delete")}
                    </Button>
                </ModalFooter>
            </Modal>

            <div className="clearfix mt-md">
                {state.currentPage > 0 && (
                    <Button
                        onClick={goToPreviousPage}
                        color="default"
                        className="float-left"
                    >
                        <i className="fa fa-angle-left text-primary mr-xs mb-xs" />
                        {t("Previous")}
                    </Button>
                )}
                {state.hasNext && (
                    <Button
                        onClick={goToNextPage}
                        color="default"
                        className="float-right"
                    >
                        {t("Next")}
                        <i className="fa ml-xs fa-angle-right text-primary mr-xs mb-xs" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default EmailTemplates;
