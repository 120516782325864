import React, { useState, useEffect, memo } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    Spinner,
    Row,
    Col,
    Input,

} from 'reactstrap';
import axios from 'axios';
import s from "../../extra/search/Search.module.scss";
import config from '../../../config';
import { useTranslation } from 'react-i18next';

const MultiSelectCompaniesModal = memo(({ type, onSelectionChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    const { t } = useTranslation();
    const toggleModal = () => setModalOpen(!modalOpen);

    useEffect(() => {
        if (modalOpen) {
            fetchCompanies();
        }
    }, [modalOpen]);

    useEffect(() => {
        // Debounce API call
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedSearchTerm !== "" || modalOpen) {
            fetchCompanies(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    const fetchCompanies = async (query = "") => {
        setLoading(true);
        setSelectedCompanies([]);
        try {
            const response = await axios.get(`/companies?typeId=${type}&q=${query}`);
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelect = (company) => {
        // company is the object, selectedCompanies is the array of objects. compare by company.id
        // check if company is already in the selectedCompanies array of objects.  If it is, remove it, if it is not, add it.
        // selectedCompanies is the array of objects, company is the object

        if (selectedCompanies.find((c) => c.id === company.id)) {
            setSelectedCompanies(selectedCompanies.filter((c) => c.id !== company.id));
        } else {
            setSelectedCompanies([...selectedCompanies, company]);
        }


    };

    const handleAdd = () => {
        if (onSelectionChange) {
            onSelectionChange(selectedCompanies);
        }
        toggleModal();
    }

    return (
        <>
            <Button color="primary" onClick={toggleModal}>
                <i className="fa fa-plus me-2"></i>
                {t("Add companies")}
            </Button>

            <Modal size="lg" isOpen={modalOpen} toggle={toggleModal}>

                <ModalBody className="bg-white">


                    <div className={s.root}>
                        <div className="mt-5 mb-4">
                            <Input
                                type="text"
                                placeholder={t("Search companies...")}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        {loading ? (
                            <div className="text-center">
                                <Spinner />
                                <p>{t("Loading companies...")}</p>
                            </div>) : companies.length > 0 ? (
                                <>

                                    <div className="mb-2 d-flex justify-content-end">


                                        <Button type="button" color="success"
                                            onClick={handleAdd}
                                            disabled={selectedCompanies.length === 0}

                                        >{t("Add companies")}</Button>
                                    </div>

                                    <hr />

                                    {companies.map((company, index) => (
                                        <section
                                            key={index}
                                            className={`mb-5 ${s.searchResultItem}`}
                                            onClick={() => handleSelect(company)}
                                        >
                                            <button className={`btn-link ${s.imageLink}`}>
                                                <img
                                                    className={s.image}
                                                    src={
                                                        company.logo
                                                            ? config.baseURL + "/" + company.logo
                                                            : "https://placehold.co/400"
                                                    }
                                                    alt=""
                                                />
                                            </button>
                                            <div className={s.searchResultItemBody}>
                                                <Row>
                                                    <Col md={9}>
                                                        <h3 className={"mb-2 " + s.searchResultItemHeading}>
                                                            {company.name}
                                                        </h3>

                                                        <p className={s.description}>
                                                            {company.description ||
                                                                t("No description available")}
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        md={3}
                                                        xs={12}
                                                        className="d-flex justify-content-center align-items-center"
                                                    >
                                                        {selectedCompanies.find(c => c.id === company.id) ? (
                                                            <i
                                                                className="fa fa-3x fa-check-square"
                                                                style={{ color: config.app.themeColors.primary }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-3x fa-square-o"
                                                                style={{ color: config.app.themeColors.primary }}
                                                            ></i>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </section>
                                    ))}

                                    <hr />

                                    <div className="mt-2 d-flex justify-content-end">

                                        <Button type="button" color="success"
                                            onClick={handleAdd}
                                            disabled={selectedCompanies.length === 0}

                                        >{t("Add companies")}</Button>
                                        <Button type="button" color="secondary" className="ms-2" onClick={toggleModal}>
                                            {t("Close")}
                                        </Button>
                                    </div>
                                </>

                            ) : (
                            <p>{t("No companies found.")}</p>
                        )
                        }

                    </div>

                </ModalBody>
            </Modal>
        </>
    );
});

export default MultiSelectCompaniesModal;
