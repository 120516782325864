import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Hammer from 'rc-hammerjs';

import UserFormPage from '../../pages/users/form/UsersFormPage';

import { SidebarTypes } from '../../reducers/layout';
import Header from '../Header';
import Sidebar from '../Sidebar/SidebarSeller';
import { openSidebar, closeSidebar, toggleSidebar } from '../../actions/navigation';
import s from './Layout.module.scss';
import { DashboardThemes } from '../../reducers/layout';
import BreadcrumbHistory from '../BreadcrumbHistory';

import CompanyInfo from '../../pages/companies/info';

import QuoteList from '../../pages/quota/list';
import QuoteCreate from '../../pages/quota/create';
import QuoteEdit from '../../pages/quota/edit';

const Layout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);

    // Redux state selectors
    const sidebarOpened = useSelector((state) => state.navigation.sidebarOpened);
    const sidebarStatic = useSelector((state) => state.navigation.sidebarStatic);
    const dashboardTheme = useSelector((state) => state.layout.dashboardTheme);

    const handleResize = useCallback(() => {
        if (window.innerWidth <= 768 && sidebarStatic) {
            dispatch(toggleSidebar());
        }
    }, [sidebarStatic, dispatch]);

    const handleSwipe = useCallback(
        (e) => {
            if ('ontouchstart' in window) {
                if (e.direction === 4) {
                    dispatch(openSidebar());
                    return;
                }

                if (e.direction === 2 && sidebarOpened) {
                    dispatch(closeSidebar());
                    return;
                }
            }
        },
        [sidebarOpened, dispatch]
    );

    // Effect to handle window resize
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <div
            className={[
                s.root,
                sidebarStatic ? `${s.sidebarStatic}` : '',
                !sidebarOpened ? s.sidebarClose : '',
                'sing-dashboard',
                `dashboard-${(localStorage.getItem('sidebarType') === SidebarTypes.TRANSPARENT ? 'light' : localStorage.getItem('dashboardTheme'))}`,
                `header-${localStorage.getItem('navbarColor') ? localStorage.getItem('navbarColor').replace('#', '') : 'FFFFFF'}`,
            ].join(' ')}
        >
            <Sidebar />
            {currentUser && <div className={s.wrap}>
                <Header />
                {/* <Helper /> */}

                <Hammer onSwipe={handleSwipe}>
                    <main className={s.content}>
                        <BreadcrumbHistory url={location.pathname} />
                        <TransitionGroup>
                            <CSSTransition
                                key={location.key}
                                classNames="fade"
                                timeout={200}
                            >
                                <Switch>
                                    <Route path="/app/main" exact render={() => currentUser?.role === "ROLE_ADMIN" ? <Redirect to={"/admin"} /> : <Redirect to={"/app/quotes"} />} />
                                    <Route path="/app/edit_profile" exact component={UserFormPage} />
                                    <Route path="/app/companies/:companyId/info" exact component={CompanyInfo} />
                                    <Route path="/app/quotes" exact component={QuoteList} />
                                    <Route path="/app/quotes/create" exact component={QuoteCreate} />
                                    <Route path="/app/quotes/:quoteId/edit" exact component={QuoteEdit} />
                                    
                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                    </main>
                </Hammer>
            </div>}
            
        </div>
    );
};

Layout.propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dashboardTheme: PropTypes.string,
};

Layout.defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
    dashboardTheme: DashboardThemes.DARK,
};

export default Layout;
