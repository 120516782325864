import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Widget from "../../components/Widget/Widget";
import GoBack from "../../components/GoBack";
import { toast } from "react-toastify";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import {
    Button,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    InputGroup, ButtonGroup,
    Col,
    Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelectField from "../../components/async-select";
import { useParams } from "react-router-dom";
import moment from "moment";
import Dropzone from 'react-dropzone';
import config from "../../config";
import { useSelector } from "react-redux";
import MultiSelectCompaniesModal from "./components/select-companies";


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").nullable(),
    description: Yup.string().required("Description is required").nullable(),
    mainCompanyId: Yup.number().required("Company is required").nullable(),
    dateCampaignStartString: Yup.date().required("Start date is required").nullable(),
    dateCampaignEndString: Yup.date()
        .required("End date is required")
        .min(
            Yup.ref("dateCampaignStartString"),
            "End date must be after start date"
        ).nullable(),
    totalSumExcTax: Yup.number()
        .typeError("Total Sum must be a number")
        .required("Total Sum is required")
        .min(0, "Total Sum must be greater than or equal to 0").nullable(),
    rightOfCreatedPost: Yup.boolean()
        .required("Please select an option")
        .nullable(),
    typeOfServiceId: Yup.number().required("Please select a type of service").nullable(),
    statusId: Yup.number().required("Please select a status").nullable(),
    connectedCompanyIds: Yup.array().required("Please select at least one connected company").nullable(),

});

const QuoteForm = () => {
    const { t } = useTranslation();
    const { quoteId } = useParams();
    const dipatch = useDispatch();
    const [currentCompany, setCurrentCompany] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [typeOfServiceOptions, setTypeOfServiceOptions] = useState([]);

    const { currentUser } = useSelector((state) => state.auth);

    const role = useMemo(() => currentUser?.role, [currentUser]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const onSelectionChange = useCallback((items) => {
        // formik.setFieldValue("connectedCompanyIds", items);
        setSelectedCompanies(items);
    }, []);

    useEffect(() => {

        formik.setFieldValue("connectedCompanyIds", selectedCompanies.map((company) => company.id));

    }, [selectedCompanies]);



    useEffect(() => {
        if (quoteId) {
            const fetchQuote = async () => {
                try {
                    const response = await axios.get(`/quotes/${quoteId}`);
                    const quote = response.data;
                    formik.setValues({
                        ...quote,
                        dateCampaignStartString: quote?.dateCampaignStart ? new Date(quote.dateCampaignStart) : null,
                        dateCampaignEndString: quote?.dateCampaignEnd ? new Date(quote.dateCampaignEnd) : null,
                        userIds: quote?.userIds ? quote.userIds : null
                    })

                } catch (error) {
                    console.error("Error fetching quote:", error);
                    toast.error(t("Failed to fetch quote. Please try again."));
                }
            };

            fetchQuote();
        }
    }, [quoteId]);

    useEffect(() => {
        const fetchTypeOfServiceOptions = async () => {
            try {
                const response = await axios.get("/options-list/quote_type_of_service");
                setTypeOfServiceOptions(response.data);
            } catch (error) {
                console.error("Error fetching typeOfService options:", error);
            }
        };

        fetchTypeOfServiceOptions();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            mainCompanyId: role === "ROLE_COMPANY" ? currentUser.userCompanyId : null,
            connectedCompanyIds: null,
            dateCampaignStartString: null,
            dateCampaignEndString: null,
            totalSumExcTax: 0,
            rightOfCreatedPost: false,
            typeOfServiceId: null,
            statusId: config.defaultStatusNewId || 6,
            totalNumberPosts: 0,
            comment: "",
            files: [],
        },
        validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                dateCampaignEndString: moment(values.dateCampaignEndString).format("YYYY-MM-DD"),
                dateCampaignStartString: moment(values.dateCampaignStartString).format("YYYY-MM-DD")
            };

            const formData = new FormData();
            Object.keys(formattedValues).forEach((key) => {
                if (key === "files") {
                    formattedValues[key].forEach((file) => {
                        formData.append("files[]", file);
                    });
                }

                else if (key === "connectedCompanyIds") {
                    formattedValues[key].forEach((id) => {
                        formData.append("connectedCompanyIds[]", id);
                    });
                }

                else {
                    formData.append(key, formattedValues[key]);
                }
            });
            try {
                setIsSubmitting(true);
                const response = await axios.post("/quotes/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                toast.success(t("Quote successfully created!"));
                dipatch(push("/admin/quotes"));
            } catch (error) {
                console.error("Error submitting form:", error);
                toast.error(t("Failed to create quote. Please try again."));
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    const handleDrop = (acceptedFiles) => {
        formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = formik.values.files.filter((_, i) => i !== index);
        formik.setFieldValue('files', newFiles);
    };

    const removeCompany = (id) => {
        setSelectedCompanies(selectedCompanies.filter((company) => company.id !== id));
    };

    useEffect(() => {
        if (currentUser?.role === "ROLE_COMPANY") {
            setCurrentCompany({
                name: currentUser?.userCompanyName,
                id: currentUser?.userCompanyId,
                typeId: currentUser?.userCompanyTypeId

            });
        }
    }
        , [currentUser]);


    return (
        <div>
            <GoBack />
            <Widget title={<h3>{t(!quoteId ? "Create quote" : "Edit")}</h3>}>
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="name">{t("Name")}</Label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.name && !!formik.errors.name}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <FormFeedback>{t(formik.errors.name)}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>


                    </Row>
                    <Row>
                        {role === "ROLE_ADMIN" && <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="mainCompanyId">{t("Main company")}</Label>
                                <AsyncSelectField
                                    url="/companies/"
                                    defaultValue={formik.values.mainCompanyId}
                                    handleSelectChange={(selectedOption) => {
                                        setCurrentCompany(selectedOption);
                                        formik.setFieldValue("mainCompanyId", selectedOption.id);
                                        setSelectedCompanies([]);
                                    }}


                                />

                                {formik.touched.mainCompanyId && formik.errors.mainCompanyId && (
                                    <div className="text-danger mt-1">
                                        {t(formik.errors.mainCompanyId)}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>}

                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="connectedCompanyIds">{t("Connected company")}</Label>
                                <div >
                                    <div className="d-flex">
                                        {selectedCompanies?.map((company, index) => (
                                            <InputGroup className="me-2" key={index} style={{
                                                width: "auto"
                                            }}>
                                                <ButtonGroup>
                                                    <Button color="primary">{company.name}</Button>
                                                    <Button color="danger" onClick={() => {
                                                        removeCompany(company.id);
                                                    }}><i className="fa fa-times" /></Button>
                                                </ButtonGroup>
                                            </InputGroup>
                                        ))}
                                        <MultiSelectCompaniesModal type={currentCompany?.typeId === 1 ? 2 : 1} onSelectionChange={onSelectionChange} />
                                    </div>
                                </div>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold">{t("Type of Service")}</Label>
                                <div>
                                    {typeOfServiceOptions.map((option) => (
                                        <FormGroup check inline key={option.id}>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="typeOfServiceId"
                                                    value={option.id}
                                                    checked={
                                                        formik.values.typeOfServiceId ===
                                                        option.id
                                                    }
                                                    onChange={() =>
                                                        formik.setFieldValue(
                                                            "typeOfServiceId",
                                                            option.id
                                                        )
                                                    }
                                                />
                                                {option.name}
                                            </Label>
                                        </FormGroup>
                                    ))}
                                </div>
                                {formik.touched.typeOfServiceId && formik.errors.typeOfServiceId && (
                                    <div className="text-danger mt-1">
                                        {t(formik.errors.typeOfServiceId)}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="description">{t("Description")}</Label>
                                <Input
                                    id="description"
                                    name="description"
                                    type="textarea"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.description &&
                                        !!formik.errors.description
                                    }
                                />
                                {formik.touched.description &&
                                    formik.errors.description && (
                                        <FormFeedback>
                                            {t(formik.errors.description)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="dateCampaignStartString">
                                    {t("Start Date")}
                                </Label>
                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style

                                        id="dateCampaignStartString"
                                        selected={formik.values?.dateCampaignStartString ? new Date(formik.values.dateCampaignStartString) : null}
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateCampaignStartString",
                                                date
                                            )
                                        }
                                        onBlur={() =>
                                            formik.setFieldTouched(
                                                "dateCampaignStartString"
                                            )
                                        }
                                        className={`form-control ${formik.touched.dateCampaignStartString &&
                                            formik.errors.dateCampaignStartString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateCampaignStartString &&
                                        formik.errors.dateCampaignStartString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateCampaignStartString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="dateCampaignEndString">
                                    {t("End Date")}
                                </Label>
                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style
                                        id="dateCampaignEndString"
                                        selected={formik.values?.dateCampaignEndString ? new Date(formik.values.dateCampaignEndString) : null}
                                        format="yyyy-MM-dd"
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateCampaignEndString",
                                                date
                                            )
                                        }
                                        onBlur={() =>
                                            formik.setFieldTouched("dateCampaignEndString")
                                        }
                                        className={`form-control ${formik.touched.dateCampaignEndString &&
                                            formik.errors.dateCampaignEndString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateCampaignEndString &&
                                        formik.errors.dateCampaignEndString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateCampaignEndString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalSumExcTax">
                                    {t("Total Sum Excluding Tax")}
                                </Label>
                                <Input
                                    id="totalSumExcTax"
                                    name="totalSumExcTax"
                                    type="number"
                                    value={formik.values.totalSumExcTax}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.totalSumExcTax &&
                                        !!formik.errors.totalSumExcTax
                                    }
                                />
                                {formik.touched.totalSumExcTax &&
                                    formik.errors.totalSumExcTax && (
                                        <FormFeedback>
                                            {t(formik.errors.totalSumExcTax)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalNumberPosts">
                                    {t("Total number posts")}
                                </Label>
                                <Input
                                    id="totalNumberPosts"
                                    name="totalNumberPosts"
                                    type="number"
                                    value={formik.values.totalNumberPosts}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.totalNumberPosts &&
                                        !!formik.errors.totalNumberPosts
                                    }
                                />
                                {formik.touched.totalNumberPosts &&
                                    formik.errors.totalNumberPosts && (
                                        <FormFeedback>
                                            {t(formik.errors.totalNumberPosts)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="rightOfCreatedPost">
                                    {t("Right of created post")}
                                </Label>
                                <div>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="rightOfCreatedPost"
                                                value={true}
                                                checked={formik.values.rightOfCreatedPost === true}
                                                onChange={() =>
                                                    formik.setFieldValue(
                                                        "rightOfCreatedPost",
                                                        true
                                                    )
                                                }
                                            />
                                            {t("True")}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="rightOfCreatedPost"
                                                value={false}
                                                checked={formik.values.rightOfCreatedPost === false}
                                                onChange={() =>
                                                    formik.setFieldValue(
                                                        "rightOfCreatedPost",
                                                        false
                                                    )
                                                }
                                            />
                                            {t("False")}
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formik.touched.rightOfCreatedPost &&
                                    formik.errors.rightOfCreatedPost && (
                                        <div className="text-danger mt-1">
                                            {t(formik.errors.rightOfCreatedPost)}
                                        </div>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Label>{t("Upload document needed, format PDF")}</Label>
                            <Dropzone onDrop={handleDrop} accept={{
                                'application/pdf': [],
                                'image/*': [],
                            }}>
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps()}

                                    >
                                        <input {...getInputProps()} />
                                        <Button color="primary" type="button">

                                            <i className="fa fa-upload me-2" />
                                            {t("Upload files")}</Button>
                                    </div>
                                )}
                            </Dropzone>
                            {formik.touched.files && formik.errors.files ? (
                                <div style={{ color: 'red' }}>{formik.errors.files}</div>
                            ) : null}
                            <ul className="mt-2">
                                {formik.values.files.map((file, index) => (
                                    <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{file.name}</span>
                                        <button type="button" className="btn btn-link"
                                            onClick={() => handleRemoveFile(index)}
                                            style={{
                                                marginLeft: '10px',
                                                cursor: 'pointer',
                                                padding: '5px 10px',
                                            }}
                                        >
                                            <i className="fa fa-times" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="statusId">{t("Status")}</Label>
                                <AsyncSelectField
                                    url="/options-list/quote_status"
                                    defaultValue={formik.values.statusId}
                                    handleSelectChange={(selectedOption) =>
                                        formik.setFieldValue("statusId", selectedOption.id)
                                    }
                                />
                                {formik.touched.statusId && formik.errors.statusId && (
                                    <div className="text-danger mt-1">
                                        {t(formik.errors.statusId)}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="comment">{t("Comment")}</Label>
                                <Input
                                    id="description"
                                    name="comment"
                                    type="textarea"
                                    value={formik.values.comment}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.comment &&
                                        !!formik.errors.comment
                                    }
                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="mt-5" type="submit" color="primary">
                        {isSubmitting ? <i className="fa fa-spin me-2 fa-spinner" /> : ""}
                        {t("Submit")}
                    </Button>
                </form>
            </Widget>
        </div >
    );
};

export default QuoteForm;
