import { useEffect, useState } from "react";

export default function useDebounce(query, delay) {
    const [deferQuery, setDeferQuery] = useState(query);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDeferQuery(query);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [query, delay]);

    return [deferQuery];
}