import React from 'react';
import PropTypes from 'prop-types';
import FormErrors from '../formErrors';
import { FastField } from 'formik';

const InputFormItemNotFast = ({
  name,
  form,
  hint,
  size,
  password,
  placeholder,
  autoFocus,
  autoComplete,
  inputProps,
  errorMessage,
  required,
  schema,
}) => {
  const { label } = schema[name];

  const sizeLabelClassName = {
    small: 'col-form-label-sm',
    large: 'col-form-label-lg',
  }[size] || '';

  const sizeInputClassName = {
    small: 'form-control-sm',
    large: 'form-control-lg',
  }[size] || '';

  return (
    <div className="form-group">
      {!!label && (
        <label
          className={`col-form-label ${
            required ? 'required' : ''
          } ${sizeLabelClassName}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        id={name}
        type={password ? 'password' : 'text'}
        onChange={form.handleChange}
        value={form.values[name] || ''}
        placeholder={placeholder || undefined}
        autoFocus={autoFocus || undefined}
        autoComplete={autoComplete || undefined}
        className={`form-control ${sizeInputClassName} ${FormErrors.validateStatus(
          form,
          name,
          errorMessage,
        )}`}
        {...inputProps}
      />
      <div className="invalid-feedback">
        {FormErrors.displayableError(form, name, errorMessage)}
      </div>
      {!!hint && <small className="form-text text-muted">{hint}</small>}
    </div>
  );
};

InputFormItemNotFast.defaultProps = {
  required: false,
};

InputFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hint: PropTypes.string,
  size: PropTypes.string,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  inputProps: PropTypes.object,
  errorMessage: PropTypes.string,
  schema: PropTypes.object.isRequired,
};

const InputFormItem = (props) => {
  return (
    <FastField name={props.name}>
      {({ form }) => <InputFormItemNotFast {...props} form={form} />}
    </FastField>
  );
};

export default InputFormItem;
