import axios from "axios";
import { useEffect, useState } from "react";

export default function useFetchingSelect(url, parseData) {
  const [field, setField] = useState();
  useEffect(() => {
    axios.get(url)
      .then(function (response) {
        setField(parseData(response.data));
      })
      .catch(function (error) {
        console.error(error);
      });

  }, [url]);
  return field;
}