import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, ButtonToolbar } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import GoBack from "../../../components/GoBack";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";


const QuoteListCompany = ({ currentUser }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [state, setState] = useState({
        quotes: [],
        isReceiving: true,
        currentPage: 0,
        hasNext: false,
    });

    const itemsPerPage = 50;

    useEffect(() => {
        const fetch = async () => {
            setState((prev) => ({
                ...prev,
                isReceiving: true,
            }));

            try {
                const limit = itemsPerPage + 1;
                const offset = state.currentPage * (limit - 1);

                const response = await axios.get("/quotes/", {
                    params: {
                        companyId: currentUser.userCompanyId,
                        limit,
                        offset,
                    },
                });

                const quotes = response.data.slice(0, itemsPerPage);
                const hasNext = response.data.length > itemsPerPage;

                setState((prev) => ({
                    ...prev,
                    quotes,
                    isReceiving: false,
                    hasNext,
                }));
            } catch (error) {
                console.error("Error fetching quotes:", error);
                setState((prev) => ({
                    ...prev,
                    isReceiving: false,
                }));
            }
        };

        fetch();
    }, [state.currentPage, currentUser.userCompanyId]);

    // Pagination handlers
    const goToNextPage = () => {
        if (state.hasNext) {
            setState((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const goToPreviousPage = () => {
        if (state.currentPage > 0) {
            setState((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    const create = () => {
        history.push(`/app/quotes/create`);
    };

    return (
        <div>
            <GoBack />
            <Row className="filter align-items-center mb-4">
                <Col md={3} xs={12}>
                    <h2>{t("Quotes")}</h2>
                </Col>
                <Col className="d-flex align-items-end justify-content-end" md={9} xs={12}>
                    <Button color="success" onClick={create}>
                        {t("Create new")}
                    </Button>
                </Col>
            </Row>
            {state.isReceiving ? (
                <Loader size={45} />
            ) : (
                <div className="table-responsive">
                    <Table className="table-bordered table-lg">
                        <thead>
                            <tr>
                                <th>{t("Name")}</th>
                                <th>{t("Seller")}</th>
                                <th>{t("Buyer")}</th>
                                <th>{t("Status")}</th>
                                <th>{t("Total Sum Excluding Tax")}</th>
                                <th>{t("Date Created")}</th>
                                <th className="text-right">{t("Actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.quotes.map((quote) => (
                                <tr key={quote.id}>
                                    <td>{quote.name}</td>
                                    <td>{quote?.sellerCompanyName ?? ""}</td>
                                    <td>{quote?.buyerCompanyName ?? ""}</td>
                                    <td>{quote.status}</td>
                                    <td>{quote.totalSumExcTax}</td>
                                    <td>
                                        {moment(quote.dateCreated).format("YYYY-MM-DD")}
                                    </td>
                                    <td>
                                        <ButtonToolbar className="justify-content-end">
                                            <Button
                                                color="success"
                                                size="xs"
                                                onClick={() =>
                                                    history.push(`/app/quotes/${quote.id}/edit`)
                                                }
                                            >
                                                {t("Edit")}
                                            </Button>

                                        </ButtonToolbar>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            <Row className="mt-md">
                <Col md={6} xs={12}>
                    {state.currentPage > 0 && (
                        <Button
                            onClick={goToPreviousPage}
                            color="primary"
                        >

                            <i className="fa mr-xs fa-angle-left" />
                            {t("Previous")}
                        </Button>
                    )}
                </Col>
                <Col md={6} xs={12} className="d-flex justify-content-end">
                    {state.hasNext && (
                        <Button
                            onClick={goToNextPage}
                            color="primary"
                        >
                            {t("Next")}
                            <i className="fa ml-xs fa-angle-right" />
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default QuoteListCompany;
