import React, { useState } from "react";
import { Field } from "formik";
import AsyncSelect from "react-select/async";
import { FormGroup, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import _ from "lodash";
import { useLocation } from "react-router";

const AsyncSelectField = ({
    name,
    label,
    url,
    handleSelectChange,
    placeholder = 'Select',
    onFocus,
    formik,
    defaultValue,
    isMulti = false,
    ...props
}) => {


  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 99999, // Set your desired z-index here
        position: 'relative',
    }),
  };


    const path  = useLocation().pathname;
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    const loadOptions = _.debounce((inputValue, callback) => {
        if (!url) {
            return;
        }
        let [baseUrl, queryString] = url.split('?');
        let params = new URLSearchParams(queryString);

        params.set('q', inputValue);
        params.set('limit', 20);

        const updatedUrl = `${baseUrl}?${params.toString()}`;

        axios.get(updatedUrl)
            .then((response) => {
                let fetchedOptions = response.data;
                const regex = /^(?!.*products\/[^?]+)(.*products(\?.*)?).*$/;

                if (name === "channelId" && regex.test(path)) {

                    fetchedOptions = [{ id: -1, channelName: t('No channel'), channelShortName: t('No channel') }, ...fetchedOptions];
                } else if (name === "addOnProductType") {
                    // this is response {"option":"option","input":"input"}
                    fetchedOptions = Object.keys(fetchedOptions).map(key => ({ id: key, name: fetchedOptions[key] }));
                }

                setOptions(fetchedOptions);
                callback(fetchedOptions);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
            });
    }, 500);

    const renderSelect = (field, form) => {
        let selectedOptions;
        if (formik) {
            selectedOptions = isMulti
                ? options.filter(option => {
                    return form.values[name]?.map(v => {
                        if (v?.id) {
                            return v.id;
                        } else {
                            return v;
                        }
                    }
                        
                        
                        )?.includes(option.id);
                })
                : options.find(option => option.id === form.values[name]) || null;
        } else {
            selectedOptions = isMulti
                ? options.filter(option => defaultValue.includes(option.id))
                : options.find(option => option.id === defaultValue) || null;


            // Check url include orders and defaultValue is not null but selectedOptions is null && name == "customerId" try to fetch the customer and push it to options
            if (path.includes('orders') && defaultValue && !selectedOptions) {
                axios.get(`/customers/${defaultValue}`)
                    .then((response) => {
                        const customer = response.data;
                        setOptions([...options, customer]);
                    })
                    .catch((error) => {
                        console.error("Error fetching customer:", error);

                    });
               
            }

        }

        return (
            <AsyncSelect
                isMulti={isMulti}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                {...(!props?.notUseCustomStyle && { menuPortalTarget: document.body })}
                {...(!props?.notUseCustomStyle && { styles: customStyles })}
      
                getOptionLabel={(option) => (name === "userIds" || name === "createdUserId") ?  option.email : (
                    url.includes('products') ? option.name + " - " + option.sku : t(option.name))}
                getOptionValue={(option) => option.id}
                value={selectedOptions}
                {...props}
                onFocus={onFocus}
                onChange={(selected) => {
                    const value = isMulti
                        ? selected ?? []
                        : selected ? selected.id : null;
                    if (form) {
                        form.setFieldValue(name, value);
                    }
                    handleSelectChange && handleSelectChange(selected);
                }}
                placeholder={t(placeholder)}
            />
        );
    };

    return (
        <>
            {formik ? (
                <FormGroup>
                    {label &&  <Label>{t(label)}</Label>}
                   
                    <Field name={name} required={!!props.required}>
                        {({ field, form }) => renderSelect(field, form)}
                    </Field>
                </FormGroup>
            ) : (
                renderSelect()
            )}
        </>
    );
};

export default AsyncSelectField;
