import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import UsersForm from './UsersForm';
import UserCreateForm from './UserCreate';
import actions from '../../../actions/usersFormActions';
import { useTranslation } from 'react-i18next';

const UsersFormPage = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const findLoading = useSelector((state) => state.users.form.findLoading);
  const saveLoading = useSelector((state) => state.users.form.saveLoading);
  const record = useSelector((state) => state.users.form.record);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const isEditing = !!id;

  const isProfile = () => {
    const currentUserId = JSON.parse(localStorage.getItem('userId'));
    return (
      id === currentUserId ||
      location.pathname === '/app/edit_profile' ||
      location.pathname === '/admin/edit_profile'
    );
  };


  useEffect(() => {
    if (isEditing) {
      dispatch(actions.doFind(id));
    } else if (isProfile()) {
      const currentUserId = JSON.parse(localStorage.getItem('userId'));
      dispatch(actions.doFind(currentUserId));
    } else {
      dispatch(actions.doNew());
    }


  }, [dispatch, id, isEditing, location.pathname]);

  const doSubmit = (id, data) => {
    if (isEditing || isProfile()) {
      dispatch(actions.doUpdate(id, data, isProfile()));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  return (
    <>
      <div className="page-top-line">
        <h2 className="page-title">
          {t(isEditing || isProfile() ? 'User edit' : 'User create')}
        </h2>
      </div>
      {
        (isEditing || isProfile()) ? (
          <UsersForm
            saveLoading={saveLoading}
            findLoading={findLoading}
            currentUser={currentUser}
            record={(isEditing || isProfile()) ? record : {}}
            isEditing={isEditing}
            isProfile={isProfile()}
            onSubmit={doSubmit}
            onCancel={() => history.push('/admin/users')}
          />
        ) : (
          <UserCreateForm  saveLoading={saveLoading} onSubmit={doSubmit} onCancel={() => history.push('/admin/users')} />
        )
      }
    </>
  );


}

export default UsersFormPage;
