import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Table, Input, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Widget from 'components/Widget';
import { useTranslation } from "react-i18next";
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import s from '../Users.module.scss';

// Utility function for debouncing
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const UsersListTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const limit = 50;

  // Fetch data from the API
  const fetchData = useCallback(async (keyword, offsetValue) => {
    setLoading(true);
    try {
      const response = await axios.get('/users', {
        params: {
          q: keyword,
          limit: limit + 1, // Fetch one extra item to check `hasMore`
          offset: offsetValue,
        },
      });

      const data = response.data;

      if (data.length > limit) {
        setHasMore(true);

      } else {
        setHasMore(false);

      }
      setRows(data.slice(0, limit)); // Use slice to limit the number of items
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Debounced Search Handler
  const debouncedFetch = useCallback(
    debounce((keyword) => {
      setOffset(0); // Reset to the first page for a new search
      fetchData(keyword, 0);
    }, 500),
    [fetchData]
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    debouncedFetch(value); // Trigger the debounced API call
  };

  // Fetch data on initial render
  useEffect(() => {
    fetchData('', 0); // Fetch all data on initial render
  }, [fetchData]);

  // Handle pagination
  const handleNext = () => {
    if (!hasMore || loading) return;
    const newOffset = offset + limit;
    setOffset(newOffset);
    fetchData(searchKeyword, newOffset);
  };

  const handlePrevious = () => {
    if (offset === 0 || loading) return;
    const newOffset = Math.max(offset - limit, 0);
    setOffset(newOffset);
    fetchData(searchKeyword, newOffset);
  };

  // Handle delete action
  const handleDelete = async () => {
    if (!userIdToDelete) return;

    try {
      setLoading(true);
      await axios.delete(`/users/${userIdToDelete}`);
      setRows((prev) => prev.filter((row) => row.id !== userIdToDelete)); // Update table
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete user:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row>
        <Col md={4}>
          <div className="page-top-line">
            <h2 className="page-title">{t("Users")}</h2>
          </div>
        </Col>
        <Col md={2}>
          {/* <input
              className="form-control"
              type="text"
              placeholder="Search"
              value={searchStr}
              onChange={searchUsers}
            /> */}
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={() => dispatch(push("/admin/users/new"))}>{t("Add user")}</Button>
          </div>
        </Col>
      </Row>
      <Widget>
        <div>
          <Input
            type="text"
            placeholder="Search"
            className="mb-3"
            value={searchKeyword}
            onChange={handleSearchChange}
          />
          {loading ? (
            <div className="d-flex justify-content-between"><Spinner color="primary" /></div>

          ) : (
            <Table responsive className={`table-striped table-hover ${s.usersListTableMobile}`}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.id}>
                      <td>{row.firstname}</td>
                      <td>{row.lastname}</td>
                      <td>{row.phone}</td>
                      <td>{row.email}</td>
                      <td>{row.role}</td>
                      <td>


                        <div className={`d-flex justify-content-between`}>

                          <Button

                            color="success"
                            size="xs"
                            onClick={() => dispatch(push(`/admin/users/${row.id}/edit`))}
                          >
                            Edit
                          </Button>
                          <Button
                            color="danger"
                            size="xs"
                            onClick={() => {
                              setUserIdToDelete(row.id);
                              setDeleteModalOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No results found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}

          <Row className="mt-md">
            <Col md={6} xs={12}>
              <Button disabled={offset === 0 || loading} onClick={handlePrevious}>
                Previous
              </Button>
            </Col>
            <Col md={6} xs={12} className="d-flex justify-content-end">
              <Button disabled={!hasMore || loading} onClick={handleNext}>
                Next
              </Button>
            </Col>
          </Row>
        </div>
      </Widget>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)}>
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>Confirm Delete</ModalHeader>
        <ModalBody className="bg-white">
          Are you sure you want to delete this user?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UsersListTable;
