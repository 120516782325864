import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Col, Row, Button, ButtonToolbar } from "reactstrap";
import { useTranslation } from "react-i18next";
import qs from "qs";
import axios from "axios";
import InputFormItem from "../../components/FormItems/items/InputFormItem";
import FormValidations from "../../components/FormItems/formValidations";
import Widget from "../../components/Widget/Widget";
import Loader from "../../components/Loader/Loader";
import GoBack from "../../components/GoBack";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import TextAreaFormItem from "../../components/FormItems/items/TextAreaFormItem";
export const CompanyCategoryEdit = ({
    location,
    match: { params: { categoryId } },
}) => {
    const { t } = useTranslation();
    const [category, setCategory] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [query, setQuery] = useState({});
    const dispatch = useDispatch();

    const schema = {
        name: { type: "string", label: "Name", required: true },
        description: { type: "string", label: "Description", required: true },
    
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get(`/company-categories/${categoryId}`);
                setCategory(response.data);
                setIsFetching(false);
            } catch (error) {
                console.error("Error fetching company category:", error);
                setIsFetching(false);
            }
        };

        if (categoryId) {
            fetch();
        } else {
            setIsFetching(false);
        }
    }, [categoryId]);

    useEffect(() => {
        setQuery(qs.parse(location.search, { ignoreQueryPrefix: true }));
    }, [location]);

    const handleSubmit = async (values) => {
        setIsUpdating(true);
        try {
            if (categoryId) {
                try {

                    await axios.post(`/company-categories/${categoryId}`, values);
                    toast.success(t("Company category updated successfully"))


                } catch (error) {
                    toast.error(t("Error updating company category"))

                }

            } else {
                try {
                    const resp = await axios.post("/company-categories/", values);
                    toast.success(t("Company category created successfully"));
                    dispatch(push(`/admin/company-categories`));

                } catch (error) {
                    toast.error(t("Error creating company category"))
                }

            }
            setIsUpdating(false);
        } catch (error) {
            console.error(t("Error updating company category:"), error);
            setIsUpdating(false);
        }
    };

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div>
            <GoBack />
            <Widget title={<h3>{t(!categoryId ? "Create new" : "Edit")}</h3>}>
                <Formik
                    onSubmit={handleSubmit}
                    enableReinitialize
                    initialValues={
                        category || { name: "", description: "" }
                    }
                    validationSchema={() => FormValidations(schema)}
                >
                    {(form) => (
                        <Row>
                            <Col md={6}>
                                <Form onSubmit={form.handleSubmit}>
                                    <InputFormItem
                                        name={"name"}
                                        label={t("Name")}
                                        required
                                        schema={schema}
                                    />
                                    <TextAreaFormItem
                                        name={"description"}
                                        label={t("Description")}
                                        required
                                        schema={schema}
                                    />
                                    <ButtonToolbar className="mt-lg">
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={isUpdating}
                                        >
                                            {isUpdating ? (
                                                <Loader />
                                            ) : !categoryId ? (
                                                t("Create")
                                            ) : (
                                                t("Save")
                                            )}
                                        </Button>
                                        <button
                                            className="btn btn-light"
                                            type="button"
                                            onClick={form.handleReset}
                                        >
                                            {t("Reset")}
                                        </button>
                                    </ButtonToolbar>
                                </Form>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </Widget>
        </div>
    );
};

export default CompanyCategoryEdit;
