import React from "react";
import { goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const GoBack = ({ className, isEditting }) => {
    const dispatch = useDispatch();
    const i18n = useTranslation();
    return (
        <Button
            className={"mb-3 " + className}
            color="primary"
            onClick={() => {
                if (isEditting) {
                    // Show alert if user is editing, if yes, then go back, if no, then stay on the page
                    if (window.confirm(i18n.t("Are you sure you want to go back?\nYou will lose all unsaved changes."))) {
                        dispatch(goBack());
                    }
                } else {
                    dispatch(goBack());
                }

            }}
        >
            <i className="fa fa-arrow-left mr-0" />
        </Button>
    );
};

export default GoBack;
