import React from 'react'

const PowerButton = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 8.08223C10 7.89948 9.81059 7.7796 9.65181 7.87007C8.21726 8.68749 7.25 10.2308 7.25 12C7.25 14.6234 9.37665 16.75 12 16.75C14.6234 16.75 16.75 14.6234 16.75 12C16.75 10.2308 15.7827 8.68749 14.3482 7.87007C14.1894 7.7796 14 7.89948 14 8.08223V9.31619C14 9.39312 14.0358 9.46532 14.0946 9.51493C14.8012 10.1111 15.25 11.0031 15.25 12C15.25 13.7949 13.7949 15.25 12 15.25C10.2051 15.25 8.75 13.7949 8.75 12C8.75 11.0031 9.19881 10.1111 9.90539 9.51493C9.96419 9.46532 10 9.39312 10 9.31619V8.08223Z" fill="black"/>
        <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V12C11.25 12.4142 11.5858 12.75 12 12.75C12.4142 12.75 12.75 12.4142 12.75 12V7Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75Z" fill="black"/>
    </svg>
)


export default PowerButton
