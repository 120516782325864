import { Formik } from 'formik';
import React, { useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import InputFormItem from '../../../components/FormItems/items/InputFormItem';
import RadioFormItem from '../../../components/FormItems/items/RadioFormItem';
import usersFields from '../usersFields';
import IniValues from '../../../components/FormItems/iniValues';
import PreparedValues from '../../../components/FormItems/preparedValues';
import FormValidations from '../../../components/FormItems/formValidations';
import Widget from '../../../components/Widget/Widget';
import { Col, Row } from 'reactstrap';
import SwitchFormItem from '../../../components/FormItems/items/SwitchFormItem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const UsersForm = ({ record, saveLoading, onSubmit, onCancel, findLoading, isEditing, isProfile }) => {
  const {t} = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  // Initialize form values
  const iniValues = () => {
    return IniValues(usersFields, record || {});
  };

  // Form validation schema
  const formValidations = () => {
    return FormValidations(usersFields, record || {});
  };

  // Handle form submission
  const handleSubmit = (values) => {
    const { id, ...data } = PreparedValues(usersFields, values || {});
    onSubmit(id, data);
  };

  if (findLoading) {
    return <Loader />;
  }

  if (isEditing && !record) {
    return <Loader />;
  }

  return (
    <Widget>
      <Formik
        onSubmit={handleSubmit}
        initialValues={iniValues()}
        validationSchema={formValidations()}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit}>
            <Row>
              <Col md={6}><InputFormItem name={'username'} schema={usersFields} /></Col>
              <Col md={6}><InputFormItem name={'personalNr'} schema={usersFields} /></Col>
            </Row>

            <Row>
              <Col md={6}><InputFormItem name={'firstname'} schema={usersFields} /></Col>
              <Col md={6}><InputFormItem name={'lastname'} schema={usersFields} /></Col>
            </Row>

            <Row>
              <Col md={6}><InputFormItem name={'email'} schema={usersFields} /></Col>
              <Col md={6}><InputFormItem name={'phone'} schema={usersFields} /></Col>
            </Row>

            <Row>
              <Col md={6}><InputFormItem password name={'plainPassword'} schema={usersFields} /></Col>
              <Col md={6}><InputFormItem password name={'confirmPassword'} schema={usersFields} /></Col>
            </Row>

            {/* Uncomment if necessary */}
            <RadioFormItem name={'role'} schema={usersFields} />

            {currentUser && currentUser.role === 'ROLE_ADMIN' && !isProfile && (
              <>
                <>
                  <SwitchFormItem name={'active'} schema={usersFields} />
                </>

              </>
            )}


            {/* <ImagesFormItem
              name={'avatar'}
              schema={usersFields}
              path={'users/avatar'}
              fileProps={{
                size: undefined,
                formats: undefined,
              }}
              max={undefined}
            /> */}

            <div className="form-buttons">
              <button
                className="me-3 btn btn-success"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit}
              >
                {t("Save")}
              </button>

              <button
                className="me-3 btn btn-gray-default"
                type="button"
                disabled={saveLoading}
                onClick={form.handleReset}
              >
                {t("Reset")}
              </button>

              <button
                className="me-3 btn btn-gray-default"
                type="button"
                disabled={saveLoading}
                onClick={onCancel}
              >
                {t("Cancel")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </Widget>
  );
};

export default UsersForm;
