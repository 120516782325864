import { Controller, useForm } from "react-hook-form";
import Widget from "../../Widget/Widget";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import useFetching from "../../../hooks/useFetchingSelect";
import Select from "react-select";
import { useState } from "react";

export default function CompanyCreatePage() {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, control } = useForm();

  let categoryOptions = useFetching('/company-categories', (items) => {
    return items.map(item => ({ value: item.id, label: item.name }));
  });

  let typeOptions = useFetching('/options-list/company_type', (items) => {
    return items.map(item => ({ value: item.id, label: item.name }));
  });

  function onSubmit(data) {
    const categoryIds = data.categoriesSelected.map((category) => category.value);
    const typeId = data.typeSelected.value;
    const dataFormatted = { ...data, typeId, categoryIds };
    console.log(dataFormatted);
    setLoading(true);
    axios.post(`/companies/`, dataFormatted)
      .then(function (response) {
        toast.success('The company has been created.');
      })
      .catch(function (error) {
        console.log(error);
        toast.error('Create new company failed.');
      })
      .finally(function () {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="page-top-line">
        <h2 className="page-title">Company - <span className="fw-semi-bold">Create</span></h2>
      </div>
      <Widget>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Company name</label>
            <input id="name" className="form-control"
              {...register("name", { required: true })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === 'required' && <p className="text-danger" role="alert">Company name is required</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="categoriesSelected" className="form-label">Category</label>
            <Controller
              name="categoriesSelected"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  {...field}
                  options={categoryOptions}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="typeSelected" className="form-label">Type</label>
            <Controller
              name="typeSelected"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={typeOptions}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="organizationNr" className="form-label">Organization Nr</label>
            <input id="organizationNr" className="form-control"
              {...register("organizationNr")}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" id="email" className="form-control"
              {...register("email", { required: true })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email?.type === 'required' && <p className="text-danger" role="alert">Email is required</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input id="phone" className="form-control"
              {...register("phone", { required: true })}
              aria-invalid={errors.phone ? "true" : "false"}
            />
            {errors.phone?.type === 'required' && <p className="text-danger" role="alert">Phone is required</p>}
          </div>
          <hr />
          <div className="mb-3">
            <label htmlFor="address" className="form-label">Address</label>
            <input id="address" className="form-control"
              {...register("address", { required: true })}
              aria-invalid={errors.address ? "true" : "false"}
            />
            {errors.address?.type === 'required' && <p className="text-danger" role="alert">Address is required</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="city" className="form-label">City</label>
            <input id="city" className="form-control"
              {...register("city", { required: true })}
              aria-invalid={errors.city ? "true" : "false"}
            />
            {errors.city?.type === 'required' && <p className="text-danger" role="alert">City is required</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="country" className="form-label">Country</label>
            <input id="country" className="form-control"
              {...register("country", { required: true })}
              aria-invalid={errors.country ? "true" : "false"}
            />
            {errors.country?.type === 'required' && <p className="text-danger" role="alert">Country is required</p>}
          </div>

          <button disabled={loading} className="btn btn-primary">Create New {loading && <Spinner size="sm" animation="border" />}</button>
        </form>
      </Widget>
    </>
  );
}