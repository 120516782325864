import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { push } from "connected-react-router";
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { useLocation } from 'react-router'
import Widget from 'components/Widget';
import useDebounce from '../../../hooks/useDebounce';
import { toast } from "react-toastify";

export default function CompaniesListPage() {
  const dispatch = useDispatch();
  let params = useParams();
  let location = useLocation();

  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [deferQuery] = useDebounce(searchQuery, 500);
  const [itemDeletedId, setItemDeletedId] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceReloadItems, setForceReloadItems] = useState(0);
  // Process query params

  // Get all companies
  useEffect(() => {

    axios.get(`/companies`, {
      params: {
        q: deferQuery,
      },
    }).then(function (response) {
      setCompanies(response.data);
      setLoading(false);
    }).catch(function (error) {
      console.error(error);
      setLoading(false);
    });
  }, [deferQuery, forceReloadItems]);

  function handleSearch(e) {
    console.log(`Seach with value: ${e.target.value}`);
    setSearchQuery(e.target.value);
  }

  // Actions
  function handleDelete() {
    console.log(`handle Delete ${itemDeletedId.current}`);
    setIsModalOpen(false);
    axios.delete(`/companies/${itemDeletedId}`)
      .then(function (response) {
        toast.success('The company has been deleted.');
        setForceReloadItems(prev => prev + 1);
      }).catch(function (error) {
        console.error(error);
        toast.error("The company failed to delete.");
      });
  }

  function handlePrevious() {
    console.log('Previous page')
  }


  function handleNext() {
    console.log('Next page')
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div><h2 className="page-title">Companies</h2></div>
        <div>
          <Link className='btn btn-primary' to={`/admin/companies/new`}>Add New Company</Link>
        </div>
      </div>
      <Widget>
        <div>
          <input placeholder="Search" type="text" className="mb-3 form-control" value={searchQuery} onChange={handleSearch} />
        </div>
        <Table responsive className='align-middle'>
          <thead>
            <tr>
              <th>#</th>
              <th>Logo</th>
              <th>Name</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? <tr><td colSpan={5} className="text-center"><Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner></td></tr> : (
              companies && companies.map(company => (
                <tr key={company.id}>
                  <td>{company.id}</td>
                  <td><img className="img-rounded" src="https://placehold.co/50" alt="" height="50" /></td>
                  <td>{company.name}</td>
                  <td>{company.type}</td>
                  <td className='width-150'>
                    <div className="d-flex justify-content-between">
                      <button type="button" className="btn btn-success btn-xs" onClick={() => {
                        dispatch(push(`/admin/companies/${company.id}/edit`))
                      }}>Edit</button>

                      <button type="button" className="btn btn-success btn-xs" onClick={() => {
                        dispatch(push(`/admin/companies/${company.id}/info`))
                      }}>Info</button>
                      <button type="button" className="btn btn-danger btn-xs" onClick={() => {
                        console.log(`Delete company: ${company.id}`);
                        setItemDeletedId(company.id)
                        setIsModalOpen(true);
                      }
                      }>Delete</button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between mt-3">
          <button type="button" disabled className="btn btn-primary" onClick={handlePrevious}>Previous</button>
          <button type="button" disabled className="btn btn-primary" onClick={handlePrevious}>Next</button>
        </div>
      </Widget>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>Confirm Delete</ModalHeader>
        <ModalBody className="bg-white">
          Are you sure you want to delete this item?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}