import React from 'react'

const Settings = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.0792L9.7872 5.3687C9.55167 5.61239 9.22729 5.75 8.88839 5.75H5.75V8.88839C5.75 9.2273 5.61239 9.55167 5.3687 9.7872L3.0792 12L5.3687 14.2128C5.61239 14.4483 5.75 14.7727 5.75 15.1116V18.25H8.88839C9.22729 18.25 9.55167 18.3876 9.7872 18.6313L12 20.9208L14.2128 18.6313C14.4483 18.3876 14.7727 18.25 15.1116 18.25H18.25V15.1116C18.25 14.7727 18.3876 14.4483 18.6313 14.2128L20.9208 12L18.6313 9.78721C18.3876 9.55168 18.25 9.2273 18.25 8.8884V5.75H15.1116C14.7727 5.75 14.4483 5.61239 14.2128 5.3687L12 3.0792ZM11.1012 1.85077C11.5926 1.34237 12.4074 1.34237 12.8988 1.85077L15.2177 4.25H18.5C19.1904 4.25 19.75 4.80965 19.75 5.5V8.78234L22.1492 11.1012C22.6576 11.5926 22.6576 12.4074 22.1492 12.8988L19.75 15.2177V18.5C19.75 19.1904 19.1904 19.75 18.5 19.75H15.2177L12.8988 22.1492C12.4074 22.6576 11.5926 22.6576 11.1012 22.1492L8.78233 19.75H5.5C4.80964 19.75 4.25 19.1904 4.25 18.5V15.2177L1.85077 12.8988C1.34237 12.4074 1.34236 11.5926 1.85077 11.1012L4.25 8.78233V5.5C4.25 4.80964 4.80964 4.25 5.5 4.25H8.78233L11.1012 1.85077Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.25 12C7.25 9.37665 9.37665 7.25 12 7.25C14.6234 7.25 16.75 9.37665 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12ZM12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z" />
    </svg>

)


export default Settings
