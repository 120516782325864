import React from "react";
import { useSelector } from "react-redux";
import QuoteListCompany from "./components/quote-list-company";
import QuoteListAdmin from "./components/quote-list-admin";

const QuoteList = () => {
    const { currentUser } = useSelector((state) => state.auth);

    if (!currentUser) {
        return null; // Or a loader/error message
    }

    return currentUser.role === "ROLE_COMPANY" ? (
        <QuoteListCompany currentUser={currentUser} />
    ) : (
        <QuoteListAdmin />
    );
};

export default QuoteList;
